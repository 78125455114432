import * as React from "react";
import { Link } from "gatsby";

import * as styles from "../styles/404.module.scss";

const NotFoundPage = () => {
  return (
    <main className={styles.main}>
      <h1>Page not found</h1>
      <button className="primary">
        <Link to="/">&larr; Go Home</Link>
      </button>
    </main>
  )
}

export default NotFoundPage;
